exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barnwood-js": () => import("./../../../src/pages/barnwood.js" /* webpackChunkName: "component---src-pages-barnwood-js" */),
  "component---src-pages-bedroom-js": () => import("./../../../src/pages/bedroom.js" /* webpackChunkName: "component---src-pages-bedroom-js" */),
  "component---src-pages-child-js": () => import("./../../../src/pages/child.js" /* webpackChunkName: "component---src-pages-child-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dining-js": () => import("./../../../src/pages/dining.js" /* webpackChunkName: "component---src-pages-dining-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-living-js": () => import("./../../../src/pages/living.js" /* webpackChunkName: "component---src-pages-living-js" */),
  "component---src-pages-office-js": () => import("./../../../src/pages/office.js" /* webpackChunkName: "component---src-pages-office-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

